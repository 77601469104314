/* Mobile: 320-480
ipads/tablets: 481-768
laptop: 769-1024
desktops: 1025-1200
tvs: 1201 */

@media only screen and (max-width: 768px) {
  .responsive-tabs__event {
    display: flex !important;
    gap: 35%;
    padding-bottom: 24px;
    border-bottom: 1px solid #e6e7e9;
  }

  .responsive-tabs__event p {
    font-size: 14px;
    line-height: 14px;
    color: #cbcccf;
    cursor: pointer;
  }

  .responsive-tabs__eventActive p {
    font-weight: 700;
    color: #4d4d33;
  }

  .responsive-section__eventInactive {
    display: none;
  }

  .event_pg__details {
    margin-top: 24px;
  }

  .event_page__details h3 {
    font-size: 24px !important;
    line-height: 32px !important;
  }

  .event_page__details p {
    font-size: 14px !important;
    line-height: 24px !important;
  }

  .event_pg__disc h3 {
    font-size: 24px !important;
    line-height: 32px !important;
  }

  .event_pg__faqLegacy p {
    font-size: 14px !important;
    line-height: 24px !important;
  }
  .event_pg__address p {
    font-size: 14px !important;
    line-height: 24px !important;
  }

  p {
    font-size: 14px !important;
    line-height: 24px !important;
  }

  .icon--sm {
    width: 18px !important;
    height: 18px !important;
    position: relative;
    top: 4px;
  }

  .event_pg__details__info__a {
    height: 155px !important;
  }
}

@media only screen and (max-width: 480px) {
  .content {
    margin-top: 16px !important;
    margin-bottom: 16px !important;
  }

  .container {
    margin-bottom: 25px !important;
  }

  .header-container {
    margin-bottom: 32px;
    display: block;
  }

  .dropdown__container {
    padding-top: 40px;
  }

  .responsive-link {
    display: block !important;
  }

  .responsive-link a {
    text-decoration: none;
  }

  .dropdown__link a {
    text-decoration: none;
  }

  .wrapper-form {
    width: 80% !important;
    margin-right: auto;
    margin-left: auto;
  }

  .header__logo {
    height: 44px;
    width: 168.13px;
  }

  .button__login {
    margin-top: 8px !important;
  }

  .button__login a {
    padding: 9px 30px !important;
    font-size: 14px !important;
    line-height: 14px !important;
  }

  .header {
    height: 64px !important;
  }

  .columns {
    flex-direction: column-reverse;
  }

  .wrapper-form-landing {
    width: 100% !important;
    height: 100% !important;
  }

  .landing__header {
    text-align: center;
  }

  .landing__header h2 {
    font-size: 24px !important;
    line-height: 32px !important;
  }

  .landing__content {
    text-align: center;
    font-size: 16px !important;
    line-height: 28px !important;
  }

  .button__brown {
    background-color: #4d4d33 !important;
    width: 100%;
    text-align: center;
    padding-top: 15px !important;
    padding-bottom: 15px !important;
    font-size: 18px !important;
    line-height: 18px !important;
  }

  .button__grey {
    width: 100%;
    text-align: center;
    padding-top: 15px !important;
    padding-bottom: 15px !important;
    font-size: 18px !important;
    line-height: 18px !important;
  }

  .button__brownSubmit {
    background-color: #4d4d33 !important;
    padding-top: 15px !important;
    padding-bottom: 15px !important;
    font-size: 18px !important;
    line-height: 18px !important;
  }

  .input {
    padding: 16px !important;
    font-size: 16px !important;
    line-height: 16px !important;
  }

  .auth__header {
    font-size: 24px !important;
    line-height: 32px !important;
  }

  .auth__input {
    padding-left: 56px !important;
    margin-bottom: 10px !important;
  }

  .auth__inputIcon {
    top: 41px !important;
  }

  .auth__inputLabel {
    font-size: 14px !important;
    line-height: 14px !important;
    position: relative;
  }

  .auth__form p {
    font-size: 14px;
    line-height: 18px;
  }

  .signup__form p {
    font-size: 14px;
    line-height: 18px;
  }

  .auth__redirect {
    font-size: 16px !important;
    line-height: 28px !important;
  }

  .main-nav {
    display: none !important;
  }

  .onboarding__header {
    width: 178px !important;
    height: 48px !important;
  }

  .onboarding__mainImage {
    max-width: 192px !important;
    max-height: 192px !important;
  }

  .onboarding__content h1 {
    font-size: 24px !important;
    line-height: 32px !important;
  }

  .onboarding__content p {
    font-size: 16px !important;
    line-height: 28px !important;
  }

  .circle-link {
    height: 24px !important;
    width: 24px !important;
  }

  .circle-inside {
    height: 18px !important;
    width: 18px !important;
    margin: 3px !important;
  }

  .banner {
    min-height: 48px !important;
    padding: 12px !important;
  }
  .banner p {
    font-weight: 400 !important;
    font-size: 14px !important;
    line-height: 24px !important;
  }

  .stepper {
    /* max-width: 328px !important; */
    width: 80% !important;
  }

  .stepper__one {
    width: 67px !important;
    padding: 16px 20px !important;
    display: block;
  }

  .stepper__one img {
    padding-bottom: 0px !important;
  }

  .stepper__one .stepper__oneStep {
    display: none;
  }

  .stepper__oneInfo {
    display: none;
  }

  .stepper__oneCurrent {
    width: 89px !important;
    flex-direction: row !important;
    gap: 10px;
  }

  .stepper__oneCurrent span {
    display: block;
    height: 24px !important;
    width: 24px !important;
  }

  .stepper__onePast span {
    display: block;
    height: 24px !important;
    width: 24px !important;
  }

  .stepper__oneFuture span {
    display: block;
    height: 24px !important;
    width: 24px !important;
  }

  .stepper__oneCurrent .stepper__oneInfo {
    display: block;
    font-size: 12px !important;
    line-height: 14px !important;
    width: 74px;
  }

  .section__header {
    margin-top: 50px !important;
    font-size: 24px !important;
    line-height: 32px !important;
    margin-bottom: 30px !important;
  }

  .inputLabel {
    font-weight: 600;
    font-size: 14px !important;
    line-height: 14px !important;
    margin-bottom: 5px !important;
  }

  footer {
    margin-top: 48px !important;
    font-size: 14px !important;
    line-height: 24px !important;
  }

  .input__socialLink span {
    height: 36px !important;
    font-size: 16px !important;
    line-height: 16px !important;
    padding-top: 20px !important;
  }

  .category__checkbox {
    max-width: 328px !important;
    margin-left: auto;
    margin-right: auto;
  }

  .responsive-nav {
    display: block !important;
    padding-top: 26px;
    padding-right: 19px;
  }

  .dashboard__message {
    margin-top: 16px !important;
    font-size: 14px !important;
    line-height: 24px !important;
    margin-bottom: 28px !important;
  }

  .dashboard__content__wagtail h2 {
    font-size: 20px !important;
    line-height: 20px !important;
  }

  .progress_bar__wrapper {
    max-width: 100% !important;
  }

  .progress__container {
    max-width: 430px !important;
  }

  .progress_bar_container {
    width: 100% !important;
  }

  .progress__complete p {
    font-size: 16px !important;
    line-height: 18px !important;
  }

  .calculatedBoxes {
    flex-wrap: wrap !important;
    flex-direction: column !important;
    gap: 16px !important;
    justify-content: flex-start !important;
  }

  .oneCalculatedBox {
    max-width: 156px !important;
    min-width: 156px !important;
    box-sizing: border-box !important;
    padding: 12px !important;
  }

  .oneCalculatedBox__header {
    font-size: 18px !important;
    line-height: 30px !important;
  }

  .oneCalculatedBox__percent {
    margin-top: 16px !important;
  }

  .oneCalculatedBox__percent p {
    font-size: 32px !important;
    line-height: 32px !important;
  }

  .event_card_img {
    max-width: 320px !important;
  }

  .dashboard__content__head h2 {
    font-size: 24px !important;
    line-height: 32px !important;
  }

  .event_card {
    margin-left: auto;
    margin-right: auto;
    max-width: 328px !important;
    width: 328px !important;
    padding: 4px !important;
    min-height: 450px !important;
    /* height: fit-content !important; */
  }

  .event__card__content {
    padding-left: 12px !important;
  }

  .event__card__content div {
    margin-bottom: 16px !important;
  }

  .event__card__content h4 {
    font-size: 16px !important;
    line-height: 24px !important;
    margin-top: 16px !important;
    margin-bottom: 24px !important;
  }

  .event__card__content p {
    font-size: 14px !important;
    line-height: 24px !important;
  }

  .event__card__action {
    margin-top: 24px;
    height: 32px !important;
  }

  .event__card__action {
    font-size: 16px !important;
  }

  .dashboard__content {
    margin-top: 0px !important;
  }

  .event_page__head__img {
    border-radius: 0px !important;
  }

  .event_pg__head {
    height: 280px !important;
    border-radius: 0px !important;
  }

  .event_pg__head__card {
    border-radius: 0px !important;
    height: 280px !important;
    padding: 24px 40px !important;
    min-width: 320px !important;
    width: 100%;
    text-align: center !important;
  }

  .event_pg__head__card h2 {
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 24px;
  }

  .event_pg__head__card p {
    font-size: 14px;
    line-height: 24px;
  }

  .footer {
    padding-bottom: 16px !important;
  }

  .footer__content {
    display: none !important;
  }

  .footer p {
    padding-top: 16px !important;
    line-height: 24px !important;
  }
}
