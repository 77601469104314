@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100;300;400;500;600;700;800;900&display=swap");

@font-face {
  font-family: "Cammron";
  src: url(webpack:///mini-css-extract-plugin//_next/static/media/Cammron-Regular.7549dace.ttf) format("truetype");
}

@import url("https://fonts.googleapis.com/css2?family=Cormorant+Garamond&family=EB+Garamond&display=swap");

:root {
  --xs: 1.35rem;
  --sm: 1.8rem;
  --lg: 2.5rem;
  --xl: 3rem;
  --rounded: 8px;

  --white: #ffffff;
  --gray-lightest: #fafcfc;
  --gray-lighter: #e5e6e6;
  --gray-light: #e5e6e6;
  --gray-primary: #abb2b2;
  --gray-dark: #7a8080;
  --gray-darker: #2f3333;
  --gray-black: #0d0e0e;

  --accent-dark: #67605f;
  --accent-primary: #4d4d33;
  --accent-light: #8a8b7c;
  --accent-lighter: #ddcdc5;
  --accent-lightest: #e5e6e6;

  --edit: #3db2ff;
  --success: #9df2b0;
  --error: #ff3636;
  --error-light: #ff5555;
  --error-message: #ffcfcf;
  --warning: #ffb900;
  --warning-dark: #d69b00;

  --font-light: thin;
  --font-medium: 300;
  --font-regular: normal;
  --font-semi: 500;
  --font-bold: 600;
  --font-xl: bold;
}

h1,
h2,
h3,
h4 {
  color: var(--accent-dark);
}
h1 {
  font-size: 3rem;
  line-height: 32px;
}

h2 {
  font-size: 40px;
  margin-block-start: 0px;
  margin-block-end: 0px;
}

h3 {
  font-size: 32px;
}

h4 {
  font-size: 24px;
}

ul {
  list-style-type: none;
  margin: 8px;
  padding: 0;
}

li {
  margin: 8px;
}

.flex {
  display: flex;
  width: 100%;
}

.spinner__container {
  display: flex;
  width: 100%;
  min-height: 400px;
  justify-content: center;
}

.spinner {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.spinner div {
  position: absolute;
  top: 33px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: var(--accent-primary);
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.spinner div:nth-child(1) {
  left: 8px;
  animation: spinner1 0.6s infinite;
}
.spinner div:nth-child(2) {
  left: 8px;
  animation: spinner2 0.6s infinite;
}
.spinner div:nth-child(3) {
  left: 32px;
  animation: spinner2 0.6s infinite;
}
.spinner div:nth-child(4) {
  left: 56px;
  animation: spinner3 0.6s infinite;
}
@keyframes spinner1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes spinner3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes spinner2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}

.flex-col {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.center {
  width: 100%;
  display: flex;
  justify-content: center;
}

.align-row {
  display: flex;
  width: 100%;
  align-items: center;
  gap: 8px;
}

.bg-black {
  background-color: black;
}

.justify-between {
  justify-content: space-between;
}

.justify-evenly {
  justify-content: space-evenly;
}
.justify-around {
  justify-content: space-around;
}

.divider {
  height: 0px;
  border-radius: var(--rounded);
  border: 1px solid var(--gray-primary);
  margin-top: 30px;
  margin-bottom: 30px;
  margin-left: 5px;
  margin-right: 5px;
}

.-circle {
  border-radius: 100%;
}

.collapse {
  transition: all 0.5s;
  margin-top: 0;
}

.expand {
  transition: all 0.5s;
  margin-top: -10rem;
}

.icon--xs {
  height: var(--xs);
  width: var(--xs);
}

.icon--sm {
  min-height: var(--sm);
  height: var(--sm);
  width: var(--sm);
}

.icon--lg {
  height: var(--lg);
  width: var(--lg);
}

.icon--xl {
  height: var(--xl);
  width: var(--xl);
}

.icon--btn {
  background-color: var(--accent-primary);
  border-radius: 100%;
}

.spin-180 {
  transform: rotate(180deg);
  transition: all 400ms;
}

.spin-360 {
  transform: rotate(180deg);
  transition: all 400ms;
}

.spin-0 {
  transform: rotate(0deg);
  transition: all 400ms;
}
.overflow-hidden {
  overflow: hidden;
}

body {
  margin: 0;
  padding: 0;
  font-family: "EB Garamond", serif;
  box-sizing: border-box;
}

p {
  margin-block-start: 0px;
  margin-block-end: 0px;
  display: inline;
  font-family: "EB Garamond", serif;
}

.container {
  width: 100%;
  box-sizing: border-box;
  margin-bottom: 56px;
}

.wrapper-90 {
  width: 90%;
  max-width: 1280px;
  margin-left: auto;
  margin-right: auto;
  box-sizing: border-box;
}

.content {
  margin-top: 72px;
  margin-bottom: 72px;
}

.columns {
  display: flex;
  justify-content: space-between;
  gap: 58px;
}

.aboutYou_Format {
  display: flex;
  width: 70%;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 20px;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 15px;
}

.signup_Format {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin-bottom: 20px;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 15px;

}

.signup-form {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.signup-form-bottom {
  margin: 0 auto 20px auto;
  display: flex; 
  flex-direction: column;
  width: 60%
}

.wrapper-form {
  
  max-width: 496px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  box-sizing: border-box;
}

.onboarding-form {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.contact-form {
  width: 60%;
  margin-left: auto;
  margin-right: auto;
}

.wrapper-form-landing {
  min-height: 496px;
  width: 50%;
  box-sizing: border-box;
  position: relative;
}

.inputLabel {
  color: #95989d;
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  margin-bottom: 6px;
}

.input {
  width: 100%;
  height: 2px;
  padding: 18px 20px;
  border-radius: 3px;
  background-color: var(--gray-lightest);
  border: 1px solid var(--gray-dark);
  color: var(--gray-black);
  font-size: 14px;
  line-height: 16px;
  margin-bottom: 12px;
  
}

.auth_input {
  width: 100%;
  padding: 16px 16px;
  border-radius: 3px;
  background-color: var(--gray-lightest);
  border: 2px solid var(--gray-dark);
  color: var(--gray-black);
  font-size: 16px;
  line-height: 16px;
  margin-bottom: 12px;
  
}

.auth__input:focus {
  outline: 1px solid var(--accent-lighter);
  border: 1px solid var(--accent-light);
}

.auth__input:focus-visible {
  border: 2px solid var(--accent-dark);
}

.input:focus {
  outline: 1px solid var(--accent-lighter);
  border: 1px solid var(--accent-light);
}

.input:focus-visible {
  border: 2px solid var(--accent-dark);
}

.textarea:focus {
  outline: 1px solid var(--accent-lighter);
  border: 1px solid var(--accent-light);
}

.textarea:focus-visible {
  border: 2px solid var(--accent-dark);
}

.input:placeholder {
  color: var(--gray-dark);
}

.checkbox--checked {
  border: 2px solid var(--accent-primary);
  background-color: var(--accent-primary);
  border-radius: 4px;
}

.checkbox--unchecked {
  border: 2px solid var(--accent-primary);
  background-color: var(--white);
  border-radius: 4px;
}

.checkbox__regularContainer {
  width: 100%;
  margin-bottom: 30px;
}

.checkbox__privacy p {
  position: relative;
}

.checkbox__regularLabel {
  display: flex;
  align-items: center;
  /* align-self: flex-start; */
  color: var(--gray-black);
  font-size: 18px;
  line-height: 25px;
  width: 100%;
}

.checkbox__regularLabel p {
  padding-left: 16px;
  width: 100%;
}

.checkbox__regularLabel img {
  margin-right: 20px;
  min-width: 32px !important;
}

.checkbox__regularCheckbox {
  display: none;
}

.auth__header {
  text-align: center;
  color: var(--gray-black);
  font-family: "Cormorant Garamond", serif;
  font-weight: 700;
  font-size: 40px;
  line-height: 60px;
}

.auth__divider {
  display: flex;
}

.auth__divider p {
  color: var(--gray-primary);
  font-weight: 500;
  font-size: 20px;
  line-height: 34px;
  margin-left: 16px;
  margin-right: 16px;
}

.auth__dividerLeft,
.auth__dividerRight {
  border-bottom: 1px solid var(--gray-light);
  width: 100%;
  position: relative;
  bottom: 18px;
}

.auth__inputContainer {
  width: 100%;
}

.auth__input {
  padding-left: 56px;
}

.auth__inputIcon {
  position: relative;
  top: 46px;
  left: 23px;
  width: 20px;
}

.auth__redirect {
  text-align: center;
  display: block;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  color: var(--gray-black);
  margin-top: 32px;
}

.auth__redirect a {
  text-decoration: none;
  padding-left: 5px;
}

.landing__header {
  font-family: "Cormorant Garamond", serif;
  font-weight: 700;

  color: #2c2c21;
}

.landing__header h2 {
  color: #2c2c21 !important;
}

.landing__header h2:not(:last-child) {
  margin-bottom: 16px;
}

.landing__header h2 {
  font-size: 40px;
  line-height: 60px;
}

.landing__content {
  margin-top: 32px;
  color: var(--gray-dark);
  font-size: 20px;
  line-height: 36px;
}

.landing__CTA {
  /* max-width: 496px; */
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 48px;
}

.onboarding__header {
  width: 100%;
  max-width: 450px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 24px;
  text-align: center;
}

.onboarding__mainImage {
  position: relative;
  padding-bottom: 80px;
  width: 100%;
  max-width: 290px;
  height: 256px;
  margin-left: auto;
  margin-right: auto;
}

.onboarding__mainBackground {
  position: absolute;
  z-index: -1000;
}

.onboarding__mainForeground {
  position: absolute;
  z-index: 100;
}

.onboarding__content {
  text-align: center;

}

.onboarding__content h1 {
  font-family: "Cormorant Garamond", serif;
  color: #2c2c21;
  font-size: 32px;
  line-height: 48px;
  margin-top: 20px;
}

.onboarding__content p {
  color: var(--gray-dark);
  font-size: 20px;
  line-height: 34px;
}

.onboarding__circleLinks {
  margin-top: 30px;
  display: flex;
  justify-content: center;
  height: 32px;
  gap: 12px;
}

.circle-link {
  height: 32px;
  width: 32px;
  border-radius: 50%;
  /* padding: 4px; */
}

.circle-active {
  border: 2px solid var(--accent-light);
}

.circle-inactive {
  border: 2px solid var(--gray-light);
}

.circle-inside {
  height: 24px;
  width: 24px;
  border-radius: 50%;
  margin: 4px;
}

.circle-inside-active {
  background-color: var(--accent-light);
}

.onboarding__buttons {
  margin-top: 48px;
  display: flex;
  justify-content: center;
  gap: 32px;
}

.button {
  text-decoration: none;
}

.button__brown {
  border: none;
  border-radius: 8px;
  background-color: #61826c;
  box-shadow: -4px 4px 25px -6px rgba(120, 72, 56, 0.15);
  font-family: "Cormorant Garamond", serif;
  font-weight: 700;
  font-size: 24px;
  line-height: 20px;
  letter-spacing: 0.02em;
  color: var(--white);
  display: block;
  white-space: nowrap;
  box-sizing: border-box;
  width: 100%;
  padding-top: 20px;
  padding-bottom: 20px;
  text-align: center;
}

.button__grey {
  background-color: var(--gray-lightest);
  border: 2px solid var(--gray-light);
  box-sizing: border-box;
  border-radius: 8px;
  color: #15181e;
  font-family: "Cormorant Garamond", serif;
  font-weight: 700;
  font-size: 24px;
  line-height: 20px;
  letter-spacing: 0.02em;
  width: 50%;
  padding-top: 20px;
  padding-bottom: 20px;
  text-align: center;
}

.button__google {
  margin-top: 32px;
  margin-bottom: 24px;
  background-color: transparent;
  display: flex;
  max-width: 250px;
  width: 100%;
  justify-content: space-around;
  align-items: center;
  border: 2px solid var(--edit);
  border-radius: 8px;
  margin-left: auto;
  margin-right: auto;
  color: var(--edit);
  font-weight: 600;
  font-size: 18px;
  line-height: 18px;
  padding-top: 21px;
  padding-bottom: 21px;
  cursor: pointer;
  box-sizing: border-box;
  letter-spacing: 0.02em;
}

.button__brownSubmit {
  border: none;
  background-color: #61826c;
  color: var(--white);
  font-family: "Cormorant Garamond", serif;
  font-weight: 700;
  font-size: 24px;
  line-height: 20px;
  letter-spacing: 0.02em;
  border-radius: 8px;
  cursor: pointer;
  white-space: nowrap;
  width: 50%;
  padding-top: 20px;
  padding-bottom: 20px;
  text-align: center;
}

.og_brownButton {
  border: none;
  background-color: #61826c;
  color: var(--white);
  font-family: "Cormorant Garamond", serif;
  font-weight: 700;
  font-size: 24px;
  line-height: 20px;
  letter-spacing: 0.02em;
  border-radius: 8px;
  cursor: pointer;
  white-space: nowrap;
  width: 100%;
  text-align: center;
}

.error__header {
  padding: 18px;
  background-color: var(--error);
  color: var(--white);
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 18px;
  font-weight: 600;
  line-height: 18px;
  border-radius: 8px 8px 0px 0px;
  box-sizing: border-box;
  margin-top: 40px;
}

.error__content {
  border-radius: 0px 0px 8px 8px;
  min-height: 45px;
  background-color: var(--error-message);
  border: 2px solid var(--error);
  padding: 16px;
  color: var(--gray-black);
  font-size: 20px;
  line-height: 34px;
  margin-bottom: 40px;
}

.error__header img {
  cursor: pointer;
}

.error-active {
  display: block;
}

.error-hidden {
  display: none;
}

.header {
  height: 115px;
  background-color: #e5e6e6;
  margin-bottom: 48px;
}

.header__content {
  display: flex;
  justify-content: space-between;
  /* align-items: center; */
  position: relative;
  z-index: 50;
}

.header__logo {
  margin-top: -5px;
}

.header__navbar {
  margin-top: 15px;
}

.button__login {
  margin-bottom: 16px;
  margin-top: 16px;
}

.button__login a {
  text-decoration: none;
  color: var(--accent-primary);
  font-family: "Cormorant Garamond", serif;
  font-size: 18px;
  font-weight: 600;
  line-height: 18px;
  padding: 15px 36px;
  border-radius: 8px;
  border: 2px solid var(--accent-primary);
}

.banner {
  box-sizing: border-box;
  min-height: 80px;
  background-color: var(--accent-light);
  padding: 30px;
  text-align: center;
}

.banner p {
  color: var(--white);
  font-weight: 600;
  font-size: 20px;
  line-height: 20px;
  letter-spacing: 0.02em;
}

.banner__active {
  display: block;
}

.banner__inactive {
  display: none;
}

.main-nav {
  display: flex;
  align-items: center;
  gap: 32px;
  position: relative;
  top: 5px;
}

.main-nav a {
  text-decoration: none;
  font-weight: 400;
  font-size: 18px;
  line-height: 18px;
  color: var(--accent-dark);
}

.main-nav a.nav__active {
  font-weight: 600;
  color: var(--accent-primary);
}

.main-nav__container {
  position: relative;
}

.nav__underline {
  width: 100%;
  height: 3px;
  background-color: var(--accent-primary);
  position: absolute;
  top: 45px;
  border-radius: 8px;
}

.main-nav__dropdownArrowActive {
  cursor: pointer;
  position: relative;
  bottom: 3px;
  width: 15px;
  height: 15px;
}

.main-nav__dropdownArrowInactive {
  opacity: 0;
  position: relative;
  width: 15px;
  margin-right: -32px;
}

.dropdown {
  position: absolute;
  min-width: 180px;
  top: 65px;
  right: -4px;
  z-index: 1000;
}

.dropdown__icon {
  margin-left: auto;
  height: 20px;
  width: 24px;
  
}

.dropdown__link a {
  white-space: nowrap;
}

.responsive-link {
  display: none;
}

.dropdown__container {
  width: 100%;
  border: 1px solid var(--accent-light);
  box-sizing: border-box;
  box-shadow: 0px 0px 50px rgba(103, 96, 95, 0.1);
  border-radius: 8px 0px 8px 8px;
  /* padding-top: 24px; */
  padding-bottom: 24px;
  /* padding-right: 24px; */
  background-color: var(--white);
}

.dropdown__container button {
  border: none;
  background-color: transparent;
  padding: 0;
}

.dropdown__container a,
.dropdown__container button {
  cursor: pointer;
  margin-left: 24px;
  margin-right: 24px;
  font-weight: 600;
  font-size: 14px;
  line-height: 14px;
  color: var(--accent-dark);
}

.dropdown__container div:not(:last-child) {
  border-bottom: 1px solid var(--gray-light);
  padding-bottom: 16px;
}

.dropdown__container div:not(:first-child) {
  padding-top: 16px;
}

.main-nav__dropdownActive {
  display: block;
}

.main-nav__dropdownInactive {
  display: none;
}

.responsive-nav__dropdownActive {
  display: block;
}

.responsive-nav__dropdownInactive {
  display: none;
}

.stepper {
  border: 2px solid var(--accent-light);
  width: 100%;
  max-width: 676px;
  margin-left: auto;
  margin-right: auto;
  border-radius: 8px;
  display: flex;
}

.stepper__one {
  padding: 16px 24px;
  /* width: 25%; */
  width: 33.33%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  color: var(--gray-primary);
}

.stepper__one img {
  padding-bottom: 24px;
}

.stepper__one p:not(:last-child) {
  text-transform: uppercase;
  padding-bottom: 12px;
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
}

.stepper__one p:not(:first-child) {
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
}

.stepper__onePast {
  color: var(--accent-light);
}

.stepper__oneCurrent {
  color: var(--white);
  background-color: var(--accent-light);
}

.stepper__oneCurrent p:last-child {
  font-weight: 600;
}

.stepper__oneFuture {
  color: var(--gray-primary);
}

.section__header {
  text-align: center;
  margin-top: 50px;
  font-family: "Cormorant Garamond", serif;
  font-weight: 700;
  font-size: 25px;
  line-height: 48px;
  color: #2c2c21;
  margin-bottom: 25px;
}

.input__regularLabel {
  margin-bottom: 2px;
  display: block;
}

.event_layout,
.event_container,
.event_content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.event_container {
  max-width: 800px;
}

.event_content img {
  width: 800px;
}

.event_content h1 {
  text-align: center;
  color: var(--accent-dark);
  font-weight: 900;
  font-size: 50px;
}

.event_content > p {
  display: flex;
  margin-bottom: 20px;

  min-height: 200px;
  min-width: 200px;
  justify-content: center;
  align-items: center;
}

.progress_bar_container {
  box-sizing: border-box;
  width: 50%;
}

.progress_bar_container h4 {
  margin: 0;
  color: #95989d;
  font-weight: 600;
  font-size: 18px;
  line-height: 18px;
  margin-bottom: 12px;
}

.progress__container {
  padding: 3px;
  border-radius: 8px;
  height: 16px;
  border: 1px solid #8a8b7c;
  width: 100%;
  max-width: 320px;
  min-width: 300px;
  box-sizing: border-box;
}

.progress__bar {
  background-color: #8a8b7c;
  height: 100%;
  border-radius: 8px;
  box-sizing: border-box;
}

.dashboard__head {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  min-height: 100px;
  justify-content: space-between;
  color: var(--accent-dark);
}

.dashboard__content {
  margin-top: 96px;
}

.dashboard__content__main {
  display: flex; 
  flex-direction: row; 
  justify-content:space-between;
}

.dashboard__content__wagtail {
  width: 100%;
  max-width: 467px;
}

.dashboard__content__wagtail h2 {
  font-family: "Cormorant Garamond", serif;
  color: #2c2c21;
}

.dashboard__head h1 {
  width: 50%;
}

.dashboard__message {
  margin-top: 12px;
  display: block;
  font-weight: 500;
  font-size: 20px;
  line-height: 34px;
  color: #95989d;
}

.dashboard__content__head {
  color: #2C333E;
  min-height: 100px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.dashboard__content__head h2 {
  color: #2C333E;
  font-family: "Cormorant Garamond", serif;
}

.dashboard__content__head > a {
  text-decoration: none;
  color: #2C333E;
  font-weight: var(--font-bold);
  font-size: 1.4rem;
  margin-right: 3rem;
}

.dashboard__content__foot {
  color: #2c2c21;
  min-height: 100px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.dashboard__content__foot > a {
  text-decoration: none;
  color: #2C333E;
  font-weight: var(--font-bold);
  font-size: 1.4rem;
  margin-right: 3rem;
}

.dashboard__content__foot h2 {
  font-family: "Cormorant Garamond", serif;
}

.dashboard__content__head__title {
  color: #2c2c21;
  padding: 2px;
  
}

.dashboard__content__component {
  background-color: #e5e6e6;
  padding: 20px;
  border-radius: 3%;
  flex: 1 0 200px;
  min-width: 200px;
  min-height: 250px;
  max-height: 250px;
  flex-shrink: 0;
  overflow: hidden;
  box-shadow: 8px 5px 5px rgba(77, 77, 51, .5);
  display:flex; 
  flex-direction: column;
  align-items:center;
  margin-left:10px;
  margin-right: 10px;
  overflow-y: scroll;
  overflow-y: visible;
}

.dashboard__content__component__pay {
  background-color: #e5e6e6;
  padding: 20px;
  border-radius: 3%;
  flex: 1 0 200px;
  min-width: 200px;
  min-height: 250px;
  max-height: 250px;
  flex-shrink: 0;
  overflow: hidden;
  box-shadow: 8px 5px 5px rgba(77, 77, 51, .5);
  display:flex; 
  flex-direction: column;
  align-items:center;
  margin-left:10px;
  margin-right: 10px;
  overflow-y: scroll;
  overflow-y: visible;
}

.dashboard__content__component__pay:hover {
  opacity: 0.7;
  cursor: pointer;
}


::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}

::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, .5);
  box-shadow: 0 0 1px rgba(255, 255, 255, .5);
}

.dashboard__content__image {
  border-radius: 50%;
  min-width: 165px;
  min-height: 165px;
  overflow: hidden;
  width: 165px;
  height: 165px;
  border-width: 2px;
  border-color: black;
  border-style: solid;
  cursor: pointer;
}

.dashboard__content__image:hover {
  opacity: .7;
}

.dashboard__content__onclick label{
  border-radius: 50%;
  min-width: 165px;
  min-height: 165px;
  overflow: hidden;
  width: 165px;
  height: 165px;

}

.event_list {
  display: flex;
  flex-flow: wrap;
  align-items: stretch;
  justify-content: flex-start;
  gap: 3rem;
  flex: 0 1 10%;
  margin-left: auto;
  margin-right: auto;
  width: 85%;
}

.event_card {
  /* min-width: 300px; */
  max-width: 496px;
  width: 496px;
  flex-grow: 1;
  color: var(--gray-dark);
  padding: 8px;
  min-height: 550px;

  background-color: var(--gray-lightest);

  border-radius: var(--rounded);
  font-weight: bold;
}

.event_card_img {
  width: 100%;
  height: 40%;
  position: relative;
  border-radius: 10px;
  overflow: hidden;
  border-radius: var(--rounded);
  border-bottom: 5px solid #2C333E;
}

.event__card__content {
  list-style: none;
  padding-left: 20px;
  font-weight: var(--font-semi);
  font-family: "Montserrat";
}

.event__card__content h4 {
  max-width: 80%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: "Cormorant Garamond", serif;
  color: #2c2c21;
}

.event__card__content div {
  width: 100%;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
}

.event__card__content p {
  margin-left: 10px;
}

.event__card__action {
  color: var(--accent-primary);
  font-size: 1.25rem;
  height: 100px;
  padding-left: 20px;
  display: flex;
  gap: 24px;
  align-items: center;
  font-family: "Cormorant Garamond", serif;
}
.event__card__btn {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  width: 30px;
  border-radius: 100%;
  background-color: var(--accent-primary);
  cursor: pointer;
}
.empty_event {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 3rem;
  color: var(--accent-dark);
}

.empty_event h2 {
  text-align: center;
  max-width: 60%;
}

.empty_event p {
  min-width: 360px;
  max-width: 50%;
  text-align: center;
  font-size: 1.25rem;
  color: var(--gray-dark);
}

.empty__events__img {
  position: relative;
  width: 100%;
  height: 300px;
}

.upcomingEvents__header h1 {
  margin-bottom: 40px;
  font-family: "Cormorant Garamond", serif;
}

.event_pg * {
  box-sizing: border-box;
}

.event_pg {
  width: 100%;
  color: var(--gray-darker);
  font-size: 1.5rem;
}

.event_pg__head {
  top: 8px;
  position: relative;
  display: flex;
  max-width: 1300px;
  align-items: center;
  transform: translate(0, -56px);
  width: 100%;
  height: 580px;
  border-bottom-right-radius: var(--rounded);
  border-bottom-left-radius: var(--rounded);
  overflow: hidden;
}

.event_pg__head__img {
  position: relative;
  width: 100%;
  height: 100%;
}

.event_pg__head__card {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: left;
  position: absolute;
  max-width: 624px;
  min-width: 550px;
  height: 484px;
  left: 0;
  padding: 48px 56px 48px 128px;
  opacity: 95%;
  border-radius: 0px 8px 8px 0px;
  background-color: var(--accent-lightest);
}

.event_pg__head__card h2 {
  color: #2c2c21;
  font-family: "Cormorant Garamond", serif;
}

.event_pg__head__card > p {
  max-width: 440px;
  height: 150px;
  overflow: hidden;
  white-space: pre-wrap;
}

.event_pg__content {
  display: flex;
  flex-flow: row;
  gap: 32px;
}

.event_pg__disc {
  font-size: 1.35rem;
  line-height: 2rem;
  max-width: 850px;
  min-height: 300px;
  flex-shrink: 1;
  border-radius: var(--rounded);
}

.event_page__details {
  font-size: 1.35rem;
  line-height: 2rem;
  max-width: 850px;
  min-height: 300px;
  flex-shrink: 1;
  border-radius: var(--rounded);
}

.event_page__details p {
  color: #2b313b;
  display: block;
  margin-bottom: 24px;
}

.event_pg__disc h3 {
  color: #2c2c21;
  font-family: "Cormorant Garamond", serif;
}

.event_pg__disc__faqs {
  width: 100%;
  border-radius: var(--rounded);
}
.event_pg__disc__faqs > div:nth-child(1) {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.event_pg__details__map,
.event_pg__details__ppl,
.event_pg__details__cat,
.event_pg__details__info__a,
.event_pg__details__info__b {
  font-size: 1.2rem;
  margin-bottom: 24px;
  background-color: #fdfdfd;
  padding: 10px;
  border-radius: var(--rounded);
  min-width: 200px;
  max-width: 300px;
  overflow: hidden;
  border-radius: var(--rounded);
}

.event_pg__details {
  border-radius: var(--rounded);
  flex-grow: 1;
  min-height: 400px;
}

.event_pg__details svg {
  margin-right: 10px;
}

.event_pg__details span {
  display: flex;
  align-items: center;
}

.event_pg__details__ppl {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2rem;
}
.event_pg__details__ppl > h1 {
  color: var(--accent-primary);
  margin: 1.22rem;
}

.event_pg__details__cat > strong {
  display: block;
  color: var(--error);
  margin-bottom: 1rem;
  margin-top: 1rem;
}

.event_pg__details__cat > p {
  font-weight: var(--font-semi);
  font-size: 1.2rem;
}

.event_pg__details__info__a {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  min-height: 200px;
  max-height: 400px;
}

.event_pg__details__info__b {
}

#map {
  height: 300px;
  min-width: 300px;
  max-width: 400px;
  overflow: hidden;
  border-radius: var(--rounded);
}

.event_pg__footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 48px;
  width: 100%;
  padding: 16px 48px 48px 48px;
  box-sizing: border-box;
  /* height: 260px; */
  border-radius: var(--rounded);
  background-color: var(--accent-lightest);
}

/* all non-media queries above this comment */

@media screen and (max-width: 600px) {
  .event_pg__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 0px;
  }

  .event_pg__details {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.textarea {
  resize: none;
  width: 115%;
  max-height: 180px;
  border-radius: 3px;
  padding: 10px;
  box-sizing: border-box;
  font-family: "Montserrat", "Times New Roman";
  background-color: var(--gray-lightest);
  border: 1px solid var(--gray-dark);
  color: var(--gray-black);
  font-size: 14px;
  line-height: 20px;
}

.select {
  width: 100%;
  padding: 10px 16px;
  border-radius: 3px;
  background-color: var(--gray-lightest);
  border: 1px solid var(--gray-dark);
  color: var(--gray-black);
  font-size: 14px;
  line-height: 16px;
}

.aboutYou {
  gap: 5px;
}

.returnToDashboard {
  margin-top: 51px;
  width: 250px;
  margin-left: auto;
  margin-right: auto;
  cursor: pointer;
}

.returnToDashboard a {
  width: 100%;
  text-align: center;
  font-weight: 600;
  font-size: 18px;
  line-height: 18px;
  color: var(--edit);
  text-decoration: underline;
}

.returnToDashboard__image {
  margin-right: 11px;
  display: inline-block;
  position: relative;
  top: 3px;
}

.input__socialLink {
  display: flex;
}

.input__socialLink span {
  height: 16px;
  background-color: #eaefef;
  border-radius: 3px 0px 0px 3px;
  padding: 12px;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: #0d0e0e;
}

.input__socialLink input {
  border-radius: 0px 3px 3px 0px;
}

.walkthrough__addLinks {
  justify-content: space-between;
  align-items: baseline;
}

.walkthrough__addLinks .icon {
  position: relative;
  top: 7px;
  cursor: pointer;
}

.walkthrough__prevNext {
  gap: 32px;
}

.progress__complete {
  display: flex;
  align-items: center;
  gap: 24px;
  margin-top: 31px;
  justify-content: flex-end;
}

.progress__complete p {
  font-weight: 600;
  font-size: 18px;
  line-height: 18px;
  color: #4d4d33;
}

.calculatedBoxes {
  display: flex;
  margin-top: 30px;
  gap: 32px;
  max-width: 1024px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}

.calculatedBoxesRow {
  display: flex;
  gap: 32px;
}

.oneCalculatedBox {
  background-color: #fafcfc;
  border: 2px solid #eaefef;
  box-sizing: border-box;
  border-radius: 8px;
  padding: 24px;
  width: 100%;
  max-width: 232px;
  min-width: 210px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 204px;
}

.oneCalculatedBox__header {
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  color: #67605f;
}

.oneCalculatedBox__percent {
  margin-top: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.oneCalculatedBox__percent p {
  font-weight: 400;
  font-size: 30px;
  line-height: 48px;
  color: #62826C;
}

.oneCalculatedBox__percentActive {
  font-weight: 600;
}

.oneCalculatedBox__stripe {
  cursor: pointer;
}

.event_pg__faqLegacy p {
  display: block;
  margin-bottom: 24px;
  font-size: 22px;
  font-family: "EB Garamond", serif;
}

.category__container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px 20px;
  margin-bottom: 48px;
}

.responsive-nav {
  display: none;
}

.category__checkbox {
  width: 100%;
  max-width: 232px;
  padding: 12px 12px 12px 12px;
  background-color: #fafcfc;
  border: 2px solid #e0e5e5;
  box-sizing: border-box;
  border-radius: 8px;
  font-size: 18px;
  line-height: 18px;
  color: #0d0e0e;
  display: flex;
  flex: 1 0 10%;
  gap: 8px;
  align-items: center;
  cursor: pointer;
}

.category__checkboxActive {
  /* background-color: black; */
  border: 2px solid #4d4d33;
  background-color: #4d4d33;
  font-weight: 500;
  color: #ffffff;
}

.category__check {
  width: 24px;
}

.additionalLinks__container li {
  display: flex;
  gap: 25px;
  margin-bottom: 25px;
}

.additionalLink__content {
  display: flex;
  gap: 12px 16px;
  font-weight: 500;
  font-size: 20px;
  line-height: 20px;
  color: #0d0e0e;
}

/* background: #EDEDEB; */
/* 48px */

.imageUpload {
  margin-top: 60px;
  margin-bottom: 48px;
  display: flex;
  flex-direction: column;
  gap: 30px;
}

#upload_widget {
  background-color: #62826C;
  border: none;
  border-radius: 8px;
  box-sizing: border-box;
  padding: 20px;
  font-size: 18px;
  font-family: "Montserrat", "Times New Roman";
  line-height: 18px;
  font-weight: 600;
}

.active__selection {
  border: 3px solid var(--accent-primary);
  border-radius: var(--rounded);
  box-shadow: 0px 0px 10px 10px #4d4d3325;
}

.inactive__selection {
  border: 3px solid transparent;
  border-radius: var(--rounded);
}

.apply_event {
  display: flex;
  justify-content: center;
  margin-bottom: 200px;
}

.apply_event__pg__content {
  max-width: 1000px;
  cursor: pointer;
}

.apply_event__your_space {
  border: 2px solid #4d4d33;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 300px;
  height: 100px;
  border-radius: var(--rounded);
  background-color: #d2d1cc;
  /* padding-bottom: 30px; */
  font-size: 1.4rem;
  font-weight: var(--font-bold);
  color: var(--accent-primary);
  margin-bottom: 50px;
}

.apply_event h2 {
  margin: 20px 0px 20px 0px;
  line-height: normal;
}

.apply_event__spaces__container {
  display: flex;
  flex-flow: row wrap;
  gap: 0.5rem;
  justify-content: space-around;
  padding-top: 1rem;
  padding-bottom: 3rem;
}

.apply_event__space_box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0;
  height: 150px;
  width: 200px;
  border-radius: var(--rounded);
  border: 2px solid var(--gray-primary);
  color: var(--gray-darker);
}

.apply_event__space_box > span {
  font-size: 2rem;
  color: var(--accent-light);
}

.apply_event__space_box > p {
  margin-top: 16px;
  font-size: 1rem;
  font-weight: var(--font-bold);
  color: var(--gray-dark);
}

.apply_event__space_box__selected {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0;
  height: 150px;
  width: 200px;
  background-color: var(--accent-light);
  border-radius: var(--rounded);
  color: var(--white);
  border: none;
}

.apply_event__space_box__selected > span {
  font-size: 2rem;
  font-weight: var(--font-bold);
  color: var(--white);
}

.apply_event__space_box__selected > p {
  color: var(--white);
}

.apply_event__card {
  border-radius: var(--rounded);
  background-color: var(--gray-lighter);
  height: 320px;
  width: 308px;
  padding: 8px;
}

.apply_event__card__img {
  height: 198px;
  width: 100%;
  position: relative;
  overflow: hidden;
  border-radius: var(--rounded);
}

.apply_event__card__btn {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 32px;
  width: 32px;
  background-color: var(--accent-primary);
  border: none;
  border-radius: 100%;
  overflow: hidden;
  cursor: pointer;
}

.apply_event__card__content {
  display: flex;
  justify-content: space-between;
  padding: 10px;
}
.apply_event__card__content > p {
  color: var(--gray-dark);
  font-weight: var(--font-bold);
  font-size: large;
}

.apply_event__card__cost {
  color: var(--accent-primary);
  font-weight: var(--font-bold);
  padding: 10px;
}

.apply_event__card__list {
  display: grid;
  grid: repeat(2, auto) / auto-flow auto;

  /* justify-content: space-between; */
  grid-gap: 2rem;
  gap: 2rem;
  padding-bottom: 100px;
}

.apply_event__checkout_btn {
  display: flex;
  justify-content: center;
}

/* Responsive, Nothing Other than Media queries should go below this line or they won't work properly */

/* view port smaller than ---> 1280px <--- 🤔 */
/* 1280px */
/* 1280px */
/* 1280px */

@media screen and (max-width: 1280px) {
  .apply_event__card {
    width: 230px;
  }
  .apply_event__card__list {
    justify-content: space-evenly;
  }
}

/* view port smaller than ---> ---> ---> 720px 😀 */
/* 720px */
/* 720px */
/* 720px */

@media screen and (max-width: 720px) {
  h1 {
    font-size: 2rem;
  }

  .apply_event__spaces__container {
    justify-content: center;
  }

  .apply_event__card {
  }
  .apply_event__card__img {
  }

  .apply_event__card__list {
    justify-content: space-evenly;
  }
}

/* VIEW PORT SMALLER THAN -->->->->->-> 600px  🧐*/
/* 600px */
/* 600px */
/* 600px */

@media screen and (max-width: 600px) {
  .apply_event__space_box {
    width: 100%;
  }

  .apply_event__card {
    width: 90vw;
    height: 65vh;
  }
  .apply_event__card__img {
    height: 60%;
  }

  .imageUpload__uploaded {
    margin-bottom: 48px;
  }

  .imageUpload__uploaded ul {
    display: flex;
    flex-wrap: wrap;
    padding: 0px;
    list-style-type: none !important;
  }

  .imageUpload__one {
    width: 50%;
    list-style-type: none !important;
    gap: 24px;
    margin-top: 8px;
    margin-bottom: 8px;
    display: block;
  }

  .imageUpload__one img {
    border-radius: 8px;
  }

  .file__count {
    text-align: center;
  }
}

.temp__applicationNotes {
  display: flex;
  flex-direction: column;
  gap: 25px;
  margin-bottom: 48px;
}

.application__selections {
  padding: 32px;
  margin-top: 48px;
  background-color: #fafcfc;
  border-radius: 8px;
  color: #67605f;
  font-size: 18px;
  line-height: 18px;
}

.application__selections h6 {
  font-weight: 600;
  font-size: 14px;
  line-height: 14px;
  color: #67605f;
}

.application__selections section {
  display: flex;
  justify-content: space-between;
}

.application__selections section:not(:first-child) {
  margin-top: 24px;
}

.application__selections section:not(:last-child) {
  margin-bottom: 24px;
}

.application__divider {
  width: 100%;
  border-bottom: 1px solid #e0e5e5;
}

.application__totalPrice {
  font-weight: 600;
  font-size: 18px;
  line-height: 18px;
  color: #4d4d33;
}

.application__splitInvoice h4,
.application__confirmCheckbox h4 {
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  color: #67605f;
}

.application__splitInvoice p {
  font-size: 18px;
  line-height: 30px;
  color: #7a8080;
}
.application__splitInvoice div {
  margin-top: 39px;
}

.application__splitInvoice div p {
  color: #0d0e0e;
}

.event_pg__address p {
  display: block;
}

.regular__navbar {
  display: flex;
  flex-direction: row;
  gap: 24px;
}

.button__signup {
  display: block;
  margin-top: 16px;
}

.button__signup a {
  text-decoration: none;
  background-color: #4d4d33;
  color: #ffffff;
  border-radius: 8px;
  padding: 15px 28px;
  font-size: 18px;
  font-weight: 600;
  line-height: 18px;
}

.general-wrapper {
  width: 100%;
  max-width: 848px;
  margin-left: auto;
  margin-right: auto;
}

.general-wrapper h1 {
  font-weight: 700;
  text-align: center;
  font-size: 32px;
  line-height: 48px;
}

.general .general__page:not(:last-child) {
  padding-bottom: 64px;
  display: block;
  border-bottom: 1px solid #abb2b2;
}

.general .general__page:not(:first-child) {
  margin-top: 64px;
  display: block;
}

.section__title {
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  color: #67605f;
  display: block;
  margin-bottom: 48px;
}

.section__content p {
  font-size: 20px;
  line-height: 34px;
  color: #7a8080;
}

.section__content b {
  font-weight: 600;
  font-size: 20px;
  line-height: 34px;
  color: #0d0e0e;
}
.section__content:not(:last-child) {
  display: block;
  margin-bottom: 24px;
}

.section__content h2,
.section__content h3,
.section__content h4 {
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  color: #2b313b;
}

.admin__layout {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: row;
  gap: 32px;
  background-color: #e5e6e6;
}

.admin__pages {
  width: 100%;
}

.admin__nav {
  width: 208px;
  height: 865px;
  background-color: #2c2c21;
  color: #ffffff;
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  padding: 19px 0px 0px 32px;
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.admin__navLinks a {
  text-decoration: none;
  color: #ffffff;
  font-weight: 500;
}

.admin__navDivider {
  margin-top: 45px;
  font-weight: 600;
  font-size: 12px;
  line-height: 12px;
  letter-spacing: 0.02em;
}

.appEventCard__appInfo p {
  display: block;
}

.responsive-tabs__event {
  display: none;
}

.footer {
  /* margin-top: 96px; */
  background-color: #e5e6e6;
  padding-bottom: 32px;
  /* padding: 31px;
  text-align: center;
  font-weight: 400;
  font-size: 18px;
  line-height: 18px;
  color: var(--accent-dark); */
}

.footer p {
  text-align: center;
  display: block;
  font-size: 18px;
  line-height: 18px;
  color: #2c2c21;
}

.footer__content {
  margin-top: 80px;
  padding-top: 75px;
  display: flex;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 48px;
  justify-content: space-between;
}

.footer__section {
  display: flex;
  flex-direction: column;
  /* width: 33%; */
  gap: 24px;
}

.footer__section p {
  width: 100%;
  max-width: 400px;
  text-align: left;
  font-size: 16px;
  line-height: 24px;
}

.footer__section h6 {
  padding: 0px;
  margin: 0px;
  font-size: 18px;
  line-height: 18px;
  color: #2c2c21;
  font-weight: 700;
}

.footer__section a {
  text-decoration: none;
  font-size: 18px;
  line-height: 18px;
  color: #2c2c21;
}

.progress_bar__wrapper {
  margin-left: auto;
  max-width: 320px;
}

.landing__image {
  max-width: 450px;
  position: relative;
  height: 496px;
  margin-left: auto;
}

.wrapper-unsubscribe {
  width: 100%;
  max-width: 714px;
  margin-left: auto;
  margin-right: auto;
}

.center__logo {
  margin-top: 48px;
  width: 200px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 48px;
}

.wrapper-unsubscribe h1 {
  text-align: center;
  margin-top: 64px;
  margin-bottom: 24px;
  font-size: 32px;
  line-height: 32px;
  font-weight: 700;
  color: #15181e;
}

.wrapper-unsubscribe p {
  text-align: center;
  display: block;
  margin-bottom: 48px;
  font-size: 20px;
  line-height: 34px;
  color: #15181e;
}

.unsubscribe__buttons {
  display: flex;
  gap: 32px;
}

.unsubscribe__oneButton {
  width: 50%;
}

#unsubscribe {
  border: none;
  background-color: transparent;
  color: #3db2ff;
  font-weight: 700;
  font-size: 24px;
  line-height: 20px;
  letter-spacing: 0.02em;
  text-align: center;
  width: 100%;
  padding-top: 20px;
  font-family: "Cormorant Garamond", serif;
}

.wrapper-notFound {
  width: 100%;
  max-width: 860px;
  margin-left: auto;
  margin-right: auto;
}

.notFound__link {
  width: 100%;
  max-width: 496px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 32px;
  display: block;
}

.forgot__password {
  margin-bottom: 4px;
}

.forgot__password a {
  text-decoration: none;
  color: #000000;
  font-weight: 700;
  font-size: 18px;
  line-height: 18px;
}

.forgot__content {
  margin-top: 16px;
  display: block;
  text-align: center;
}

.wrapper-walkthroughSuccess {
  width: 100%;
  max-width: 672px;
  margin-left: auto;
  margin-right: auto;
}

.walkthroughSuccess__backgroundImage {
  width: 100%;
  max-width: 256px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 48px;
}

.wrapper-walkthroughSuccess h1 {
  color: #2c2c21;
  text-align: center;
  font-weight: 700;
  font-size: 32px;
  line-height: 48px;
  margin-bottom: 80px;
}

.wrapper-walkthroughSuccess p {
  text-align: center;
  display: block;
  font-size: 20px;
  line-height: 34px;
  margin-bottom: 98px;
  color: #95989d;
}

.eventDetails__section {
  margin-bottom: 32px;
}

.eventDetails__box {
  padding: 24px 16px;
  background: #fdfdfd;
  width: 100%;
  max-width: 320px;
  display: flex;
  flex-direction: column;
  gap: 27px;
  border-radius: 8px;
}

.eventDetails__header {
  font-weight: 500;
  margin: 0px !important;
}


.eventDetails__box p {
  display: block;
  font-size: 18px;
  line-height: 18px;
  color: #15181e;
}

.eventDetails__box a {
  text-decoration: none;
  cursor: pointer;
  font-weight: 500;
  font-size: 18px;
  line-height: 18px;
  color: #3db2ff;
}

.event__applied {
  height: 30px !important;
}

.shoppers__divider {
  width: 100%;
  margin-top: 24px;
  margin-bottom: 24px;
}

.dropdown__linkFooter button {
  border: none !important;
  cursor: pointer;
  padding: 0;
  background-color: #e5e6e6;
}

.dropdown__linkPayment {
  text-align: left !important;
  font-size: 18px !important;
  line-height: 18px !important;
  color: #2c2c21 !important;
  position: relative;
  right: px;
}

.applied_event_card {
  /* min-width: 300px; */
  max-width: 496px;
  width: 496px;
  flex-grow: 1;
  color: var(--gray-dark);
  padding: 8px;
  min-height: -moz-fit-content;
  min-height: fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border: 2px solid #61826c;
  background-color: var(--gray-lightest);

  border-radius: var(--rounded);
  font-weight: bold;
}

.applied_event_card_img {
  width: 100%;
  height: 256px;
  position: relative;
  border-radius: 10px;
  overflow: hidden;
  border-radius: var(--rounded);
}

.event__detailsRow {
  display: flex;
  gap: 8px;
  justify-content: space-between;
  align-items: center;
}

.event__detailsRow p{
  margin-left: 10px;
}

.event__detailsBottom {
  display: flex;
  margin-top: 5px;
  gap: 8px;
  justify-content: space-between;
  align-items: center;
}

.change_password__blurb {
  margin-bottom: 48px;
  display: block;
  text-align: center;
}

.service-based {
  text-align: center;
  margin-bottom: 25px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.accordion {
  background-color: #61826c;
  color: var(--accent-lightest);
  font-size: 20px;
  font-weight: 700;
  cursor: pointer;
  padding: 18px;
  width: 100%;
  text-align: left;
  border-radius: 8px;
  border: none;
  outline: none;
  transition: 0.4s;
  font-family: "Cormorant Garamond", serif;
  margin-bottom: 5px;
}

.accordion:after {
  content: '\02795'; /* Unicode character for "plus" sign (+) */
  font-size: 20px;
  color: var(--accent-lightest);
  float: right;
  margin-left: 5px;

}


.active, .accordion:hover {
  background-color: var(--accent-light);
}

.panel {
  padding: 0 18px;
  background-color: white;
  
}

.faq_container {
  display: block;
  width: 100%;
  
}

.accountProfile__header {
  font-size: 40px;
  font-weight: 700;
  line-height: 60px;
  color: #2c2c21;
}

.accountProfile__title {
  font-weight: 700;
  font-weight: 32px;
  line-height: 48px;
  color: #2c2c21;
}

.accountProfile__store {
  width: -moz-fit-content;
  width: fit-content;
  background-color: #8a8b7c;
  padding: 39px 46px;
  border-radius: 8px;
  color: white;
  font-size: 18px;
  line-height: 18px;
  cursor: pointer;
}

.accountProfile__title a {
  color: #3db2ff;
  text-decoration: none;
  font-family: "Cormorant Garamond", serif;
}

.accountStore__header {
  font-weight: 700;
  font-size: 40px;
  line-height: 60px;
  color: #2c2c21;
  margin-bottom: 96px;
  font-family: "Cormorant Garamond", serif;
}

.myEvents__links {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.products__section {
  margin-bottom: 95px;
}

.product {
  padding: 16px;
  border: 2px solid #d2d1cc;
  border-radius: 8px;
  min-width: 320px;
  display: flex;
  justify-content: space-between;
}

.products__container {
  display: flex;
  gap: 32px;
}

.product__title {
  color: #2c2c21;
  font-weight: 700;
  font-size: 18px;
  line-height: 18px;
}

.product__price {
  color: #4d4d33;
  font-weight: 700;
  font-size: 18px;
  line-height: 18px;
}

.product__info {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.product__active {
  border: 2px solid #4d4d33;
}

.event__facebook {
  display: flex;
  gap: 16.5px;
  align-items: center;
}

.event__facebook__url {
  color: #3d6ad6;
  font-weight: 700;
  font-size: 18px;
  line-height: 18px;
  position: relative;
  bottom: 3px;
}

.event__facebook a {
  text-decoration: none;
}

.store__picture__list {
  display: flex;
  flex-direction: column;
  justify-content: center;
  
}

.store__image__container {
  height: 165;
  width: 165;
  border-width: 1.5px;
  overflow: hidden;
  border-radius: 1%;
  margin: 20px;
}

/* cormorant-garamond-cyrillic-ext-400-normal */
@font-face {
  font-family: 'Cormorant Garamond';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url(/_next/static/media/cormorant-garamond-cyrillic-ext-400-normal.3f99f6a9.woff2) format('woff2'), url(/_next/static/media/cormorant-garamond-cyrillic-ext-400-normal.384aa9a8.woff) format('woff');
  unicode-range: U+0460-052F,U+1C80-1C88,U+20B4,U+2DE0-2DFF,U+A640-A69F,U+FE2E-FE2F;
}

/* cormorant-garamond-cyrillic-400-normal */
@font-face {
  font-family: 'Cormorant Garamond';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url(/_next/static/media/cormorant-garamond-cyrillic-400-normal.85858b8d.woff2) format('woff2'), url(/_next/static/media/cormorant-garamond-cyrillic-400-normal.8611cf5a.woff) format('woff');
  unicode-range: U+0301,U+0400-045F,U+0490-0491,U+04B0-04B1,U+2116;
}

/* cormorant-garamond-vietnamese-400-normal */
@font-face {
  font-family: 'Cormorant Garamond';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url(/_next/static/media/cormorant-garamond-vietnamese-400-normal.8714ceed.woff2) format('woff2'), url(/_next/static/media/cormorant-garamond-vietnamese-400-normal.a686f5b6.woff) format('woff');
  unicode-range: U+0102-0103,U+0110-0111,U+0128-0129,U+0168-0169,U+01A0-01A1,U+01AF-01B0,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+1EA0-1EF9,U+20AB;
}

/* cormorant-garamond-latin-ext-400-normal */
@font-face {
  font-family: 'Cormorant Garamond';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url(/_next/static/media/cormorant-garamond-latin-ext-400-normal.48e189e6.woff2) format('woff2'), url(/_next/static/media/cormorant-garamond-latin-ext-400-normal.c5a8f100.woff) format('woff');
  unicode-range: U+0100-02AF,U+0304,U+0308,U+0329,U+1E00-1E9F,U+1EF2-1EFF,U+2020,U+20A0-20AB,U+20AD-20C0,U+2113,U+2C60-2C7F,U+A720-A7FF;
}

/* cormorant-garamond-latin-400-normal */
@font-face {
  font-family: 'Cormorant Garamond';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url(/_next/static/media/cormorant-garamond-latin-400-normal.fca20caa.woff2) format('woff2'), url(/_next/static/media/cormorant-garamond-latin-400-normal.e7c5c5a7.woff) format('woff');
  unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+0304,U+0308,U+0329,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD;
}
/* eb-garamond-cyrillic-ext-400-normal */
@font-face {
  font-family: 'EB Garamond';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url(/_next/static/media/eb-garamond-cyrillic-ext-400-normal.334f7391.woff2) format('woff2'), url(/_next/static/media/eb-garamond-cyrillic-ext-400-normal.8bc952d5.woff) format('woff');
  unicode-range: U+0460-052F,U+1C80-1C88,U+20B4,U+2DE0-2DFF,U+A640-A69F,U+FE2E-FE2F;
}

/* eb-garamond-cyrillic-400-normal */
@font-face {
  font-family: 'EB Garamond';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url(/_next/static/media/eb-garamond-cyrillic-400-normal.f2eeed8d.woff2) format('woff2'), url(/_next/static/media/eb-garamond-cyrillic-400-normal.ebb89a2e.woff) format('woff');
  unicode-range: U+0301,U+0400-045F,U+0490-0491,U+04B0-04B1,U+2116;
}

/* eb-garamond-greek-ext-400-normal */
@font-face {
  font-family: 'EB Garamond';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url(/_next/static/media/eb-garamond-greek-ext-400-normal.ca15cbe6.woff2) format('woff2'), url(/_next/static/media/eb-garamond-greek-ext-400-normal.ab701adb.woff) format('woff');
  unicode-range: U+1F00-1FFF;
}

/* eb-garamond-greek-400-normal */
@font-face {
  font-family: 'EB Garamond';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url(/_next/static/media/eb-garamond-greek-400-normal.8a5fa516.woff2) format('woff2'), url(/_next/static/media/eb-garamond-greek-400-normal.7effce67.woff) format('woff');
  unicode-range: U+0370-0377,U+037A-037F,U+0384-038A,U+038C,U+038E-03A1,U+03A3-03FF;
}

/* eb-garamond-vietnamese-400-normal */
@font-face {
  font-family: 'EB Garamond';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url(/_next/static/media/eb-garamond-vietnamese-400-normal.16fe19c8.woff2) format('woff2'), url(/_next/static/media/eb-garamond-vietnamese-400-normal.6f8c17a9.woff) format('woff');
  unicode-range: U+0102-0103,U+0110-0111,U+0128-0129,U+0168-0169,U+01A0-01A1,U+01AF-01B0,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+1EA0-1EF9,U+20AB;
}

/* eb-garamond-latin-ext-400-normal */
@font-face {
  font-family: 'EB Garamond';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url(/_next/static/media/eb-garamond-latin-ext-400-normal.ed5e9126.woff2) format('woff2'), url(/_next/static/media/eb-garamond-latin-ext-400-normal.a9781fff.woff) format('woff');
  unicode-range: U+0100-02AF,U+0304,U+0308,U+0329,U+1E00-1E9F,U+1EF2-1EFF,U+2020,U+20A0-20AB,U+20AD-20C0,U+2113,U+2C60-2C7F,U+A720-A7FF;
}

/* eb-garamond-latin-400-normal */
@font-face {
  font-family: 'EB Garamond';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url(/_next/static/media/eb-garamond-latin-400-normal.8455855d.woff2) format('woff2'), url(/_next/static/media/eb-garamond-latin-400-normal.9d98722d.woff) format('woff');
  unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+0304,U+0308,U+0329,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD;
}
/* Mobile: 320-480
ipads/tablets: 481-768
laptop: 769-1024
desktops: 1025-1200
tvs: 1201 */

@media only screen and (max-width: 768px) {
  .responsive-tabs__event {
    display: flex !important;
    gap: 35%;
    padding-bottom: 24px;
    border-bottom: 1px solid #e6e7e9;
  }

  .responsive-tabs__event p {
    font-size: 14px;
    line-height: 14px;
    color: #cbcccf;
    cursor: pointer;
  }

  .responsive-tabs__eventActive p {
    font-weight: 700;
    color: #4d4d33;
  }

  .responsive-section__eventInactive {
    display: none;
  }

  .event_pg__details {
    margin-top: 24px;
  }

  .event_page__details h3 {
    font-size: 24px !important;
    line-height: 32px !important;
  }

  .event_page__details p {
    font-size: 14px !important;
    line-height: 24px !important;
  }

  .event_pg__disc h3 {
    font-size: 24px !important;
    line-height: 32px !important;
  }

  .event_pg__faqLegacy p {
    font-size: 14px !important;
    line-height: 24px !important;
  }
  .event_pg__address p {
    font-size: 14px !important;
    line-height: 24px !important;
  }

  p {
    font-size: 14px !important;
    line-height: 24px !important;
  }

  .icon--sm {
    width: 18px !important;
    height: 18px !important;
    position: relative;
    top: 4px;
  }

  .event_pg__details__info__a {
    height: 155px !important;
  }
}

@media only screen and (max-width: 480px) {
  .content {
    margin-top: 16px !important;
    margin-bottom: 16px !important;
  }

  .container {
    margin-bottom: 25px !important;
  }

  .header-container {
    margin-bottom: 32px;
    display: block;
  }

  .dropdown__container {
    padding-top: 40px;
  }

  .responsive-link {
    display: block !important;
  }

  .responsive-link a {
    text-decoration: none;
  }

  .dropdown__link a {
    text-decoration: none;
  }

  .wrapper-form {
    width: 80% !important;
    margin-right: auto;
    margin-left: auto;
  }

  .header__logo {
    height: 44px;
    width: 168.13px;
  }

  .button__login {
    margin-top: 8px !important;
  }

  .button__login a {
    padding: 9px 30px !important;
    font-size: 14px !important;
    line-height: 14px !important;
  }

  .header {
    height: 64px !important;
  }

  .columns {
    flex-direction: column-reverse;
  }

  .wrapper-form-landing {
    width: 100% !important;
    height: 100% !important;
  }

  .landing__header {
    text-align: center;
  }

  .landing__header h2 {
    font-size: 24px !important;
    line-height: 32px !important;
  }

  .landing__content {
    text-align: center;
    font-size: 16px !important;
    line-height: 28px !important;
  }

  .button__brown {
    background-color: #4d4d33 !important;
    width: 100%;
    text-align: center;
    padding-top: 15px !important;
    padding-bottom: 15px !important;
    font-size: 18px !important;
    line-height: 18px !important;
  }

  .button__grey {
    width: 100%;
    text-align: center;
    padding-top: 15px !important;
    padding-bottom: 15px !important;
    font-size: 18px !important;
    line-height: 18px !important;
  }

  .button__brownSubmit {
    background-color: #4d4d33 !important;
    padding-top: 15px !important;
    padding-bottom: 15px !important;
    font-size: 18px !important;
    line-height: 18px !important;
  }

  .input {
    padding: 16px !important;
    font-size: 16px !important;
    line-height: 16px !important;
  }

  .auth__header {
    font-size: 24px !important;
    line-height: 32px !important;
  }

  .auth__input {
    padding-left: 56px !important;
    margin-bottom: 10px !important;
  }

  .auth__inputIcon {
    top: 41px !important;
  }

  .auth__inputLabel {
    font-size: 14px !important;
    line-height: 14px !important;
    position: relative;
  }

  .auth__form p {
    font-size: 14px;
    line-height: 18px;
  }

  .signup__form p {
    font-size: 14px;
    line-height: 18px;
  }

  .auth__redirect {
    font-size: 16px !important;
    line-height: 28px !important;
  }

  .main-nav {
    display: none !important;
  }

  .onboarding__header {
    width: 178px !important;
    height: 48px !important;
  }

  .onboarding__mainImage {
    max-width: 192px !important;
    max-height: 192px !important;
  }

  .onboarding__content h1 {
    font-size: 24px !important;
    line-height: 32px !important;
  }

  .onboarding__content p {
    font-size: 16px !important;
    line-height: 28px !important;
  }

  .circle-link {
    height: 24px !important;
    width: 24px !important;
  }

  .circle-inside {
    height: 18px !important;
    width: 18px !important;
    margin: 3px !important;
  }

  .banner {
    min-height: 48px !important;
    padding: 12px !important;
  }
  .banner p {
    font-weight: 400 !important;
    font-size: 14px !important;
    line-height: 24px !important;
  }

  .stepper {
    /* max-width: 328px !important; */
    width: 80% !important;
  }

  .stepper__one {
    width: 67px !important;
    padding: 16px 20px !important;
    display: block;
  }

  .stepper__one img {
    padding-bottom: 0px !important;
  }

  .stepper__one .stepper__oneStep {
    display: none;
  }

  .stepper__oneInfo {
    display: none;
  }

  .stepper__oneCurrent {
    width: 89px !important;
    flex-direction: row !important;
    gap: 10px;
  }

  .stepper__oneCurrent span {
    display: block;
    height: 24px !important;
    width: 24px !important;
  }

  .stepper__onePast span {
    display: block;
    height: 24px !important;
    width: 24px !important;
  }

  .stepper__oneFuture span {
    display: block;
    height: 24px !important;
    width: 24px !important;
  }

  .stepper__oneCurrent .stepper__oneInfo {
    display: block;
    font-size: 12px !important;
    line-height: 14px !important;
    width: 74px;
  }

  .section__header {
    margin-top: 50px !important;
    font-size: 24px !important;
    line-height: 32px !important;
    margin-bottom: 30px !important;
  }

  .inputLabel {
    font-weight: 600;
    font-size: 14px !important;
    line-height: 14px !important;
    margin-bottom: 5px !important;
  }

  footer {
    margin-top: 48px !important;
    font-size: 14px !important;
    line-height: 24px !important;
  }

  .input__socialLink span {
    height: 36px !important;
    font-size: 16px !important;
    line-height: 16px !important;
    padding-top: 20px !important;
  }

  .category__checkbox {
    max-width: 328px !important;
    margin-left: auto;
    margin-right: auto;
  }

  .responsive-nav {
    display: block !important;
    padding-top: 26px;
    padding-right: 19px;
  }

  .dashboard__message {
    margin-top: 16px !important;
    font-size: 14px !important;
    line-height: 24px !important;
    margin-bottom: 28px !important;
  }

  .dashboard__content__wagtail h2 {
    font-size: 20px !important;
    line-height: 20px !important;
  }

  .progress_bar__wrapper {
    max-width: 100% !important;
  }

  .progress__container {
    max-width: 430px !important;
  }

  .progress_bar_container {
    width: 100% !important;
  }

  .progress__complete p {
    font-size: 16px !important;
    line-height: 18px !important;
  }

  .calculatedBoxes {
    flex-wrap: wrap !important;
    flex-direction: column !important;
    gap: 16px !important;
    justify-content: flex-start !important;
  }

  .oneCalculatedBox {
    max-width: 156px !important;
    min-width: 156px !important;
    box-sizing: border-box !important;
    padding: 12px !important;
  }

  .oneCalculatedBox__header {
    font-size: 18px !important;
    line-height: 30px !important;
  }

  .oneCalculatedBox__percent {
    margin-top: 16px !important;
  }

  .oneCalculatedBox__percent p {
    font-size: 32px !important;
    line-height: 32px !important;
  }

  .event_card_img {
    max-width: 320px !important;
  }

  .dashboard__content__head h2 {
    font-size: 24px !important;
    line-height: 32px !important;
  }

  .event_card {
    margin-left: auto;
    margin-right: auto;
    max-width: 328px !important;
    width: 328px !important;
    padding: 4px !important;
    min-height: 450px !important;
    /* height: fit-content !important; */
  }

  .event__card__content {
    padding-left: 12px !important;
  }

  .event__card__content div {
    margin-bottom: 16px !important;
  }

  .event__card__content h4 {
    font-size: 16px !important;
    line-height: 24px !important;
    margin-top: 16px !important;
    margin-bottom: 24px !important;
  }

  .event__card__content p {
    font-size: 14px !important;
    line-height: 24px !important;
  }

  .event__card__action {
    margin-top: 24px;
    height: 32px !important;
  }

  .event__card__action {
    font-size: 16px !important;
  }

  .dashboard__content {
    margin-top: 0px !important;
  }

  .event_page__head__img {
    border-radius: 0px !important;
  }

  .event_pg__head {
    height: 280px !important;
    border-radius: 0px !important;
  }

  .event_pg__head__card {
    border-radius: 0px !important;
    height: 280px !important;
    padding: 24px 40px !important;
    min-width: 320px !important;
    width: 100%;
    text-align: center !important;
  }

  .event_pg__head__card h2 {
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 24px;
  }

  .event_pg__head__card p {
    font-size: 14px;
    line-height: 24px;
  }

  .footer {
    padding-bottom: 16px !important;
  }

  .footer__content {
    display: none !important;
  }

  .footer p {
    padding-top: 16px !important;
    line-height: 24px !important;
  }
}

